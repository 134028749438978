import { createSlice } from '@reduxjs/toolkit'
import {SS, getUrlParameter, getFirstMerchantCode, getFilteredShopCodes} from "../utils/common";
import { color as defaultColor } from '../components/Theming/Colors/default'
import { color as woodwoodColor } from '../components/Theming/Colors/woodwood'
import { color as marysiaColor } from '../components/Theming/Colors/marysia'
import { color as aycaneColor } from '../components/Theming/Colors/aycane'
import { color as douchebagsColor } from '../components/Theming/Colors/douchebags'
import { color as bymalinaColor } from '../components/Theming/Colors/bymalina'
import { color as royalDesignColor } from '../components/Theming/Colors/royal_design'
import { color as eightyEightyFourtyEightyColor } from '../components/Theming/Colors/8848'
import { color as henryLloydColor } from '../components/Theming/Colors/henrylloyd'
import { color as hhColor } from '../components/Theming/Colors/hh'
import { color as chimiColor } from '../components/Theming/Colors/chimi'
import { color as mustoColor } from '../components/Theming/Colors/musto'
import { color as bjornBorgColor } from '../components/Theming/Colors/bjornborg'
import { color as rum21Color } from '../components/Theming/Colors/rum21'
import { color as ogoshipColor } from '../components/Theming/Colors/ogoship'
import { color as totemeColor } from '../components/Theming/Colors/toteme'
import { color as asketColor } from '../components/Theming/Colors/asket'
import { color as snsColor } from '../components/Theming/Colors/sns'
import { color as indiskaColor } from '../components/Theming/Colors/indiska'
import { color as stutterheimColor } from '../components/Theming/Colors/stutterheim'
import { color as quizColor } from '../components/Theming/Colors/quiz'
import { color as vitvaruexpertenColor } from '../components/Theming/Colors/vitvaruexperten'
import { color as sportshopenColor } from "../components/Theming/Colors/sportshopen"
import { color as sportshopenOutletColor } from "../components/Theming/Colors/sportshopen_outlet"
import { color as swedemountColor } from "../components/Theming/Colors/swedemount"
import { color as arenaColor } from "../components/Theming/Colors/arena"
import { color as galvinGreenColor } from "../components/Theming/Colors/galvin_green"
import { fonts as defaultFonts } from '../components/Theming/Fonts/default'
import { fonts as woodwoodFonts } from '../components/Theming/Fonts/woodwood'
import { fonts as marysiaFonts } from '../components/Theming/Fonts/marysia'
import { fonts as aycaneFonts } from '../components/Theming/Fonts/aycane'
import { fonts as douchebagsFonts } from '../components/Theming/Fonts/douchebags'
import { fonts as bymalinaFonts } from '../components/Theming/Fonts/baymalina'
import { fonts as royalDesignFonts } from '../components/Theming/Fonts/royal_design'
import { fonts as eightyEightyFourtyEightyFonts } from '../components/Theming/Fonts/royal_design'
import { fonts as henryLloydFonts } from '../components/Theming/Fonts/henrylloyd'
import { fonts as hhFonts } from '../components/Theming/Fonts/hh'
import { fonts as chimiFonts } from '../components/Theming/Fonts/chimi'
import { fonts as mustoFonts } from '../components/Theming/Fonts/musto'
import { fonts as bjornBorgFonts } from '../components/Theming/Fonts/bjornborg'
import { fonts as rum21Fonts } from '../components/Theming/Fonts/rum21'
import { fonts as ogoshipFonts } from '../components/Theming/Fonts/ogoship'
import { fonts as totemeFonts } from '../components/Theming/Fonts/toteme'
import { fonts as asketFonts } from '../components/Theming/Fonts/asket'
import { fonts as snsFonts } from '../components/Theming/Fonts/sns'
import { fonts as quizFonts } from '../components/Theming/Fonts/quiz'
import { fonts as vitvaruexpertenFonts } from '../components/Theming/Fonts/vitvaruexperten'
import { fonts as indiskaFonts } from '../components/Theming/Fonts/indiska'
import { fonts as stutterheimFonts } from '../components/Theming/Fonts/stutterheim'
import { fonts as sportshopenFonts } from '../components/Theming/Fonts/sportshopen';
import { fonts as sportshopenOutletFonts } from '../components/Theming/Fonts/sportshopen_outlet';
import { fonts as swedemountFonts } from '../components/Theming/Fonts/swedemount';
import { fonts as arenaFonts } from '../components/Theming/Fonts/arena';
import { fonts as galvinGreenFonts } from '../components/Theming/Fonts/galvin_green';
import {merchantThemes} from "../components/Variable";
const buildShopId = process.env.REACT_APP_BS || 42000;
const defaultTheme = process.env.REACT_APP_TH || null;

let themingFonts = {
	default: defaultFonts,
	marysia: marysiaFonts,
	woodwood: woodwoodFonts,
	aycane: aycaneFonts,
	douchebags: douchebagsFonts,
	bymalina: bymalinaFonts,
	royal_design: royalDesignFonts,
	'8848': eightyEightyFourtyEightyFonts,
	henrylloyd: henryLloydFonts,
	hh: hhFonts,
	hh_ww: hhFonts,
	chimi: chimiFonts,
	musto: mustoFonts,
	bjornborg: bjornBorgFonts,
	rum21: rum21Fonts,
	ogoship: ogoshipFonts,
	toteme: totemeFonts,
	asket: asketFonts,
	quiz: quizFonts(),
	vitvaruexperten: vitvaruexpertenFonts,
	sns: snsFonts,
	indiska: indiskaFonts(),
	stutterheim: stutterheimFonts,
	sportshopen: sportshopenFonts,
	sportshopen_outlet: sportshopenOutletFonts,
	swedemount: swedemountFonts,
	arena: arenaFonts,
	galvin_green: galvinGreenFonts
}

let themingColors = {
	default: defaultColor,
	marysia: marysiaColor,
	woodwood: woodwoodColor,
	aycane: aycaneColor,
	douchebags: douchebagsColor,
	bymalina: bymalinaColor,
	royal_design: royalDesignColor,
	'8848': eightyEightyFourtyEightyColor,
	henrylloyd: henryLloydColor,
	hh: hhColor,
	hh_ww: hhColor,
	chimi: chimiColor,
	musto: mustoColor,
	bjornborg: bjornBorgColor,
	rum21: rum21Color,
	ogoship: ogoshipColor,
	toteme: totemeColor,
	asket: asketColor,
	sns: snsColor,
	quiz: quizColor,
	vitvaruexperten: vitvaruexpertenColor,
	indiska: indiskaColor,
	stutterheim: stutterheimColor,
	sportshopen: sportshopenColor,
	sportshopen_outlet: sportshopenOutletColor,
	swedemount: swedemountColor,
	arena: arenaColor,
	galvin_green: galvinGreenColor
}

/**
 * getTheme {Funciton}
 * @description - get current theme;
 * @return {Object}
 *
 * */
const getTheme = () => {
	let shopProperties = SS.get('shopProperties') || null
	let themeName = defaultTheme ? defaultTheme :
		shopProperties && shopProperties['ui.theme']
			? shopProperties['ui.theme'].toLowerCase().trim()
			: 'default'
	let themeCurrentName =
		themingFonts[themeName] && themingColors[themeName]
			? themeName
			: 'default'
	let theme = {
		...themingFonts[themeCurrentName],
		color: themingColors[themeCurrentName],
		name: themeCurrentName,
	}
	return theme
}

const getShopId = (theme='') => {
	let shopId = buildShopId
	switch (theme) {
		case merchantThemes.marysia:
			shopId = 13000;
			break;
		case merchantThemes.woodwood:
			shopId = 16000;
			break;
		case merchantThemes.douchebags:
			shopId = 33000;
			break;
		case merchantThemes.bjornborg:
			shopId = 31000;
			break;
		case merchantThemes.musto:
			shopId = 50000;
			break;
		case merchantThemes.royal_design:
		case merchantThemes.rum21:
			shopId = 6000;
			break;
		case merchantThemes.chimi:
			shopId = 11000;
			break;
		case merchantThemes.henrylloyd:
			shopId = 12000;
			break;
		case merchantThemes.hh:
		case merchantThemes.hh_ww:
			shopId = 1;
			break;
		case merchantThemes.aycane:
			shopId = 25000;
			break;
		case merchantThemes.bymalina:
			shopId = 17000;
			break;
		case merchantThemes['8848']:
			shopId = 19000;
			break;
		case merchantThemes.ogoship:
			shopId = 18000;
			break;
		case merchantThemes.toteme:
			shopId = 36000;
			break;
		case merchantThemes.asket:
			shopId = 7000;
			break;
		case merchantThemes.sns:
			shopId = 40000;
			break;
		case merchantThemes.quiz:
			shopId = 22000;
			break;
		case merchantThemes.vitvaruexperten:
			shopId = 44000;
			break;
		case merchantThemes.indiska:
			shopId = 21000;
			break;
		case merchantThemes.stutterheim:
			shopId = 43000;
			break;
		case merchantThemes.sportshopen:
		case merchantThemes.sportshopen_outlet:
		case merchantThemes.swedemount:
			shopId = 42000;
            break;
		case merchantThemes.arena:
			shopId = 27000;
            break;
		case merchantThemes.galvin_green:
			shopId = 39000;
            break;
		default:
			shopId = buildShopId
			break;
	}

	return shopId;
}

/**
 * getDefaultMessages {Function}
 * @description - generate the default language message object necessary fro localization
 * @return {Object}
 *
 * */
const getDefaultMessages = () => {
	const cacheLanguage = SS.get('language');
	let allowedLanguages = {
		en: {},
		fr: {},
		no: {},
		nl: {},
		it: {},
		da: {},
		de: {},
		es: {},
		fi: {},
		ko: {},
		jp: {},
		sv: {}
	}

	if (cacheLanguage) {
		return {
			...allowedLanguages,
			[cacheLanguage]: {}
		}
	}

	return allowedLanguages
}

/**
 * refreshWidget {Function}
 * @description - refresh widget using new locale and shopId data
 *
 * */
const refreshWidget = (newShopId, newLanguage) => {
	if(window.rWidget) {
		let {shopId, language} = window.rWidget.getCurrentSettings();
		if(window.rWidget?.codes[newShopId] !== shopId || newLanguage !== language) {
			let widgetRefreshData = {
				shopId: newShopId,
				language: newLanguage
			}
			window.rWidget.refresh(widgetRefreshData)
		}
	}
}

export const rootSlice = createSlice({
	name: 'root',
	initialState: {
		isLoading: false,
		themes: {
			default: themingFonts['default']
		},
		currentTheme: getTheme(),
		messages: getDefaultMessages(),
		shopId: getUrlParameter('shopId') || SS.get('shopId') || getShopId(),
		shopProperties: SS.get('shopProperties') || {},
		locale: getUrlParameter('language') || SS.get('language') || null,
        shopCodes: null,
		merchantShopCode: getUrlParameter('shopCode') || null,
		countryId: getUrlParameter('countryId') || null
	},
	reducers: {
		getPropertiesStart: (state) => {
			state.isLoading = true
		},
		getPropertiesSuccess: (state, { payload }) => {
			state.isLoading = false
			let properties = payload.reduce((acc, item) => {
				acc[item.name] = item.value
				return acc
			}, {})
			const currentThemeName = defaultTheme ? defaultTheme : properties['ui.theme']?.toLowerCase()
			const themeName =
				themingFonts[currentThemeName] &&
				themingColors[currentThemeName]
					? currentThemeName
					: 'default'
			const uiTheme = state?.themes[themeName]
			state.currentTheme = {
				...uiTheme,
				color: themingColors[themeName],
				name: themeName,
			}
			state.locale = properties['shop.language'] || 'en';

			SS.set('shopProperties', state.shopProperties)
			//state.shopId = getShopId(currentThemeName)
			state.shopProperties = properties

			//refreshWidget(state.merchantShopCode, state.locale)
		},
		getPropertiesError: (state) => {
			state.isLoading = false
		},
		getLanguageProviderStart: (state) => {
			state.isLoading = true
		},
		getLanguageProviderSuccess: (
			state,
			{ payload: { currentLang, messages } }
		) => {
			state.isLoading = false;
			state.locale = currentLang;
			state.messages[currentLang] = messages;

			refreshWidget(state.merchantShopCode, state.locale)

		},
		getLanguageProviderError: (state) => {
			state.isLoading = false
		},
		setLanguage: (state, { payload }) => {
			state.locale = payload
			refreshWidget(state.merchantShopCode, state.locale)
		},
		clearReturnadoToken: (state, {payload}) => {
			state.returnadoToken = null
		},
        setShopCodes: (state, {payload}) => {
			let filteredCodes = getFilteredShopCodes(payload, state.currentTheme.name);
		    state.shopCodes = filteredCodes;
		    if(!state.merchantShopCode) {
				state.merchantShopCode = getFirstMerchantCode(filteredCodes || JSON.parse(SS.get('shopCodes')));
			}
			SS.set('shopCodes', filteredCodes);
        },
		setShopCodes2: (state, {payload}) => {
			let filteredCodes = getFilteredShopCodes(payload.codes, state.currentTheme.name);
			state.shopCodes = filteredCodes;
			if(!state.merchantShopCode) {
				state.merchantShopCode = payload.merchantMarketCode;
			}
			SS.set('shopCodes', filteredCodes);
		},
        setShopId:  (state, {payload}) => {
            state.shopId = payload.value;
            state.merchantShopCode = parseInt(payload.label, 10);
			SS.set('shopId', payload.value)
			refreshWidget(state.merchantShopCode, state.locale, state.returnadoToken)
        }
	},
})

export const {
	getPropertiesSuccess,
	getPropertiesStart,
	getPropertiesError,
	setLanguage,
	getLanguageProviderError,
	getLanguageProviderStart,
	getLanguageProviderSuccess,
	clearReturnadoToken,
    setShopCodes,
	setShopCodes2,
    setShopId
} = rootSlice.actions
export const { reducer } = rootSlice
