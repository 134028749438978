import styled, {css} from 'styled-components'
import {rem} from '../../utils/common'
import {device, merchantThemes, fonts} from '../../components/Variable'

export const MainBackground = styled.div`
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  
  .scrollbar-container{
    padding: 0 0 ${100} 0;
  }
  
  ${p => p.theme.name === merchantThemes.woodwood && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/wood_wood_bg.jpg") #ffffff no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.douchebags && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/douchebags_bg.jpg") #EFE4DE no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.aycane && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/aycane_bg.jpg") #19232D no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.bymalina && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/bymalina_bg.jpg") #DCC1A4 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.royal_design && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/royal_design_bg.jpg") #9CB490 no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes['8848'] && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/8848_bg.jpg") #CFDEE3 no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.henrylloyd && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/henry_lloyd_bg.jpg") #7C8991 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.hh && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/hh/HH+Returns+background.jpg") #AED1E7 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.hh_ww && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/hh/hh-ww_bg.jpg") #424A4E no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.chimi && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/chimi_bg.jpg") #BCB9B4 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.musto && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/musto_bg.jpg") #D2D3D5 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.bjornborg && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/bb/bb_bg.jpg") #CA9F59 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.rum21 && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/rum21_bg.jpg") #7EA377 no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.ogoship && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/ogo_bg.jpg") #ffffff no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.asket && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/asket/asket_bg.jpg") #e0ddd8 no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.sns && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/standalone/sns_bg.jpg") #8fbdee no-repeat center/cover;
  `}
  
    ${p => p.theme.name === merchantThemes.indiska && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/indiska/indiska_desktop_bg.jpg") #f8e9db no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.marysia && css`
      background-color: ${({theme}) => theme.color.secondary};
      background-repeat: no-repeat, no-repeat;
      background-position: center;
      background-size: cover;
  `}

  ${p => p.theme.name === merchantThemes.quiz && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/quiz/quiz_bg.jpg") #C5B2A7 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.vitvaruexperten && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/vitvaruexperten/vitvaruexperten_bg.jpg") #F6EFE7 no-repeat center/cover;
  `}
  
  ${p => p.theme.name === merchantThemes.sportshopen && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/sportshopen/sportshopen_bg_new.jpg") #EDD7C0 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.sportshopen_outlet && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/sportshopen/sportshopen_outlet_bg.jpg") #A695A2 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.swedemount && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/sportshopen/swedemount_bg.jpg") #FBFBFB no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.stutterheim && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/stutterheim/stutterheim_bg.jpg") #CAC0BE no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.arena && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/arena/arena_bg.jpg") #A94E49 no-repeat center/cover;
  `}

  ${p => p.theme.name === merchantThemes.galvin_green && css`
    background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/galvingreen/galvin_green_bg.jpg") #F4F0EF no-repeat center/cover;
  `}

  ${p => p.isOpen && css`
    background-position: center bottom ${rem(-200)}, center;
  `}
`

export const MainContent = styled.div`
  max-width: ${rem(589)};
  padding-bottom: ${rem(20)};
  margin: 0 auto;
  
  @media (${device.tablet}) {
    width: 90%;
    margin-top: 0;
  }
`
export const MainContentLanguageWrapper = styled.div`
  position: absolute;
  display: flex;
  left: ${rem(45)};
  top: ${rem(15)};
  width: 100%;
  z-index: 10;
  
  @media (${device.tablet}) {
    position: relative;
    display: flex;
    left: auto;
    top: auto;
    width: 100%;
    margin: ${rem(16)} auto ${rem(5)} auto;
  }
  
  @media (${device.mobileL}) {
    justify-content: space-between;
    
    > div:first-child {
      margin: 0;
    }
    > div {
      width: 50%;
    }
  }
`

export const MainLogoImg = styled.img`
  display: block;
  margin: ${rem(58)} auto ${rem(40)} auto;
  width: 100%;
  max-width: ${rem(200)};
  transition: all .2s;
  opacity: 1;


  ${p => p.theme.name === merchantThemes.bymalina && css`
    margin: ${rem(78)} auto ${rem(40)} auto;
    max-width: ${rem(450)};
  `}

  ${p => p.theme.name === merchantThemes.royal_design && css`
    margin: ${rem(78)} auto ${rem(40)} auto;
    max-width: ${rem(380)};
    filter: drop-shadow(${rem(-20)} ${rem(-20)} ${rem(15)} white) drop-shadow(0 0 ${rem(15)} white) drop-shadow(${rem(20)} ${rem(20)} ${rem(15)} white); 
  `}
  
  ${p => p.theme.name === merchantThemes.bjornborg && css`
    margin: ${rem(78)} auto ${rem(40)} auto;
    max-width: ${rem(380)};
  `}
  
  ${p => p.theme.name === merchantThemes.toteme && css`
    margin: ${rem(70)} auto ${rem(0)} auto;
    max-width: ${rem(100)};
  `}
  
  ${p => p.theme.name === merchantThemes.sns && css`
    max-width: ${rem(100)};
  `}

  ${p => p.theme.name === merchantThemes.sportshopen && css`
    max-width: ${rem(140)};
    margin: ${rem(44)} auto ${rem(60)} auto;
    @media (${device.desktopFHD}) {
      max-width: ${rem(110)};
      margin-top: ${rem(30)};
    }
  `}

  ${p => p.theme.name === merchantThemes.sportshopen_outlet && css`
    max-width: ${rem(240)};
    margin: ${rem(60)} auto;
    @media (${device.desktopFHD}) {
      max-width: ${rem(180)};
      margin: ${rem(35)} auto;
    }
  `}

  ${p => p.theme.name === merchantThemes.swedemount && css`
    max-width: ${rem(200)};
    margin: ${rem(30)} auto;
  `}

  ${p => p.theme.name === merchantThemes.vitvaruexperten && css`
    max-width: ${rem(260)};
    margin: ${rem(45)} auto ${rem(35)} auto;
  `}

  @media (${device.tablet}) {
    width: 90%;
    margin: ${rem(20)} auto;
  }
  
  @media (${device.mobileL}) {
    width: ${rem(140)};
    
    ${p => p.theme.name === merchantThemes.bymalina && css`
        width: ${rem(240)};
    `}
    
    ${p => p.theme.name === merchantThemes.royal_design && css`
        width: ${rem(240)};
    `}
    
    ${p => p.theme.name === merchantThemes.bjornborg && css`
        width: ${rem(240)};
    `}
    
    ${p => p.theme.name === merchantThemes.toteme && css`
        max-width: ${rem(80)};
    `}
    
    ${p => p.theme.name === merchantThemes.sns && css`
        max-width: ${rem(80)};
    `}
    
  }

  ${p => p.isOpen && css`
    opacity: 0;
  `}
`

export const MainLogoImgWrapper = styled.div`
    display: block;
    max-width: ${rem(416)};
    margin: ${rem(58)} auto ${rem(40)} auto;
    width: 100%;
    max-width: ${rem(200)};
    transition: all .2s;
    opacity: 1;

    @media (${device.tablet}) {
        width: 90%;
        margin: ${rem(25)} auto ${rem(10)} auto;
    }
    
    ${p => p.theme.name === merchantThemes.woodwood && css`
      max-width: ${rem(400)};
      background-color: ${({theme}) => theme.color.secondary};
      box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
      border-radius: ${rem(8)};
      padding: ${rem(2)} ${rem(10)};
    `}
    
    ${p => p.isOpen && css`
      opacity: 0;
    `}
    
    ${p => p.noImage && css`
      min-height: ${rem(53)};
      visibility: hidden;
          svg {
            display: none;
          }
    `}
`

export const Title = styled.div`
  text-align: center;
  cursor: pointer;
  position: relative;
  font-size: ${rem(20)};
  margin-bottom: ${rem(15)};
  transition: margin-bottom 0.5s;
 
  ${p => p.theme.name === merchantThemes.bymalina && css`
      font-size: ${rem(17.12)};
      margin-bottom: ${rem(0)};
      line-height: ${rem(26)};
  `}
    
  ${(p) => p.theme.name === merchantThemes.toteme && css`
      font-size: ${rem(15)};
      letter-spacing: ${rem(0.06)};
      line-height: ${rem(20)};
  `}

  ${p => p.theme.name === merchantThemes.quiz && css`
      font-family: ${fonts.Playfair};
  `}
  ${p => p.theme.name === merchantThemes.indiska && css`
      font-family: ${fonts.BatonTurbo};
      font-size: ${rem(16.12)};
  `}
  
  @media (${device.tablet}) {
    font-size: ${rem(15)};
    ${p => !p.isOpen && css`
      margin-bottom: ${rem(0)};
    `}
    
    ${p => p.theme.name === merchantThemes.bymalina && css`
         line-height: ${rem(20)};
    `}
    
    ${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
    `}
  }

  svg {
    width: 100%;
  
    ${p => p.isOpen && css`
      transform: rotate(-180deg);
    `}
  }

  ${p => p.theme.name === merchantThemes.woodwood && css`
    font-weight: 600;
  `}
  
  ${(p) => p.theme.name === merchantThemes.toteme && css`
      margin-bottom: ${rem(1)};
      font-size: ${rem(15)};
      letter-spacing: ${rem(0.06)};
      line-height: ${rem(20)};
  `}
`

export const ArrowControlWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    transition: all .3s;
    transform: rotate(0deg);
    width: ${rem(24)};
    height: ${rem(24)};
    border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
    padding: ${rem(5)};
    z-index: 10;
    
    ${p => p.theme.name === merchantThemes.toteme && css`
     width: ${rem(19)};
    height: ${rem(19)};
  `}
    
    @media (${device.tablet}) {
        width: ${rem(20)};
        height: ${rem(20)};
        right: ${rem(0)};
        top: 0;
    }
    
    ${p => p.isOpen && css`
      transform: rotate(-180deg);
    `}
    
    ${p => p.theme.name === merchantThemes.marysia && css`
      border: ${rem(1)} solid ${({theme}) => theme.color.grayFour};
      border-radius: ${rem(0)};
      
      svg {
        fill: ${({theme}) => theme.color.grayFour};
      }
    `}
    
    ${p => p.theme.name !== merchantThemes.toteme && css`
      border-radius: ${rem(5)};
    `}
    
    ${p => p.theme.name === merchantThemes.toteme && css`
      border: 0;
      svg {
        top: ${rem(2)};
        position: relative;
      }
    `}
    
    
`

export const MainText = styled.div`
  background-color: ${({theme}) => theme.color.secondary};
  padding: ${rem(15, 32, 32, 32)};
  border: ${rem(1)} solid ${({theme}) => theme.color.primary};
  width: 100%;
  margin-top: ${rem(20)};
  position: relative;
  z-index: 3;
  max-height: ${rem(58)};
  overflow: hidden;
  transition: all .3s;
  
  ${p => p.theme.name !== merchantThemes.toteme && css`
     box-shadow: 0 ${rem(-1)} ${rem(14)} ${rem(2)} rgba(0, 0, 0, 0.2);
     border-radius: ${rem(8)};
  `}
  
  @media (${device.tablet}) {
    max-height: ${rem(50)};
    padding: ${rem(10, 10, 10, 10)};
  }
  ${p => p.isOpen && css`
    max-height: 200vh !important;
  `}
  
  ${p => p.theme.name === merchantThemes.woodwood && css`
     box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
     border-radius: ${rem(6)};
  `}
  
  ${p => p.theme.name === merchantThemes.aycane && css`
     border-radius: ${rem(6)};
  `}
  
  ${p => p.theme.name === merchantThemes.marysia && css`
     display: none;
  `}
  
  ${p => p.theme.name === merchantThemes.toteme && css`
     border: 0;
     padding: ${rem(5, 32)};
  `}
`
export const BoxAnimation = styled.div`
  position: relative;
  margin-bottom: ${rem(60)};
  
  @media (${device.tablet}) {
    margin-bottom: ${rem(60)};
  }
`
export const AccordionWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s;
  max-height: 0;
  
  ${p => p.isOpen && css`
    max-height: 400vh;
  `}
  
  ${p => p.theme.name === merchantThemes.toteme && css`
     padding: ${rem(0, 10)};
  `}
  
`

export const EmptyLogoWrapper = styled.div`
  height: ${rem(120)};
  @media (${device.tablet}) {
    height: ${rem(35)};
  }
`